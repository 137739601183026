import React from "react";
import { graphql } from "gatsby";
import get from "lodash/get";
import DynamicPage from "../templates/dynamicPage";

function Index(props) {
  const page = get(props, "data.contentfulPage");

  return <DynamicPage page={page}></DynamicPage>;
}

export default Index;

export const pageQuery = graphql`
  query indexQuery($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          language
          ns
          data
        }
      }
    }
    contentfulPage(slug: { eq: "/" }, node_locale: { eq: $language }) {
      id
      slug
      title
      node_locale
      brandTheme
      seo {
        ... on ContentfulSeo {
          ...SeoFragment
        }
      }
      pageBanner {
        ... on ContentfulPageBanner {
          ...PageBannerFragment
        }
      }
      body {
        __typename
        ... on ContentfulList {
          ...ListFragment
        }
        ... on ContentfulHero {
          ...HeroFragment
        }
        ... on ContentfulVideo {
          ...VideoFragment
        }
        ... on ContentfulSlider {
          ...SliderFragment
        }
        ... on ContentfulGenericComponent {
          type
        }
        ... on ContentfulImageBoxes {
          ...ImageBoxesFragment
        }
        ... on ContentfulQuote {
          ...QuoteFragment
        }
        ... on ContentfulImageWithText {
          ...ImageWithTextFragment
        }
        ... on ContentfulFloatingImageWithVideoAndText {
          ...FloatingImageWithVideoAndText
        }
      }
    }
  }
`;
